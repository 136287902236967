@import "~ngx-spinner/animations/ball-circus.css";

li {
  font-size: large;
}

div.panel-heading {
 font-size: x-large;
}

.claimgrid div.slickgrid-container div.slick-header-columns div.column-header.ui-state-default.slick-header-column {
  background-color: #2151a4;
  color: white;
}

.claimgrid div.slickgrid-container div.slick-header-columns div.column-header.ui-state-hover.slick-header-column {
  background-color: #337ab7;
  color: white;
}

.claimgrid div.slickgrid-container div.slick-cell {
  cursor: pointer;
}

.claimgrid div.slickgrid-container div.slick-row.odd{
  background-color: #efefef;
  background-image: none;
}
.alerttext {
  color: red;
}

.COAMain {
  max-width: 600px !important;
  max-height: 600px !important;
}

.COAClose {
    cursor: pointer;
    position: absolute;
    font-size: large;
    font-weight: bold;
    top: 5px;
    right: -90px;
}
.COAPopup {
    display: block;
    max-width: 600px;
    max-height: 600px;
}
